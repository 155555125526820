.home {
  overflow: hidden;
}

.home > .bannerWrapper {
  background-color: var(--primary-text-color);
  display: flex;
  height: 60px;
  overflow: hidden;
  position: relative;
}

.home > .bannerWrapper > .banner {
  animation: sideScroll 7.5s linear infinite;
  color: var(--background-color);
  font-family: var(--secondary-font-family);
  font-size: 18px;
  font-weight: 800;
  position: absolute;
  transform: translateX(200%);
  white-space: nowrap;
}

.home > .bannerWrapper > .banner:last-of-type {
  animation-delay: 3.75s;
}

@keyframes sideScroll {
  from {
    transform: translateX(200%);
  }

  to {
    transform: translateX(-100%);
  }
}

.content {
  padding: 2em;
}

h2.headline {
  font-size: 54px;
  font-weight: 900;
  margin-top: .8em;
  margin-bottom: 0;
  text-align: right;
}

.experience {
  display: flex;
  flex-direction: column;
  padding-top: 5em;
  position: relative;
  right: .45em;
  top: -1em;
}

.job {
  align-items: center;
  display: flex;
  margin-bottom: 3em;
  margin-left: auto;
  position: relative;
  text-align: right;
}

.job > .text {
  animation: fadeInAndShift 2s ease forwards;
  animation-delay: 1.25s;
  opacity: 0;
  position: relative;
}

@keyframes fadeInAndShift {
  from {
    opacity: 0;
    top: 1em;
  }

  to {
    opacity: 1;
    top: 0em;
  }
}

.job > .text > .company {
  font-size: 14px;
  font-family: var(--secondary-font-family);
  margin: 0;
  margin-bottom: .4em;
}

.job > .text > .title {
  font-size: 12px;
  font-weight: 900;
  margin: 0;
  text-transform: uppercase;
}

.job > .text > .dates {
  color: #848484;
  font-family: var(--secondary-font-family);
  font-size: 12px;
  margin: 0;
}

.job > .marker {
  animation: fadeIn 2s ease forwards;
  animation-delay: 1.25s;
  background-color: var(--background-color);
  border: 2px solid var(--primary-text-color);
  border-radius: 50px;
  content: '';
  display: block;
  height: 10px;
  margin-left: 2em;
  opacity: 0;
  position: relative;
  right: -.35em;
  width: 10px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.job.current > .marker {
  background-color: var(--primary-text-color);
}

.experience > .line {
  animation: drawLine 1s linear forwards;
  background-color: var(--primary-text-color);
  display: block;
  height: 0%;
  position: absolute;
  right: 0;
  top: 0;
  width: 2px;
}

@keyframes drawLine {
  from {
    height: 0;
  }
  to {
    height: 87%;
  }
}

.gradientOverlay {
  background: var(--lower-gradient);
  bottom: 0;
  height: 150px;
  width: 100%;
  position: fixed;
  z-index: 1;
}
