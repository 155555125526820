@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;600&display=swap');

.App {
  --background-color: #F1F1F1;
  --primary-font-family: 'Roboto';
  --secondary-font-family: 'Roboto Mono';
  --primary-text-color: #141414;
  --lower-gradient: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(241,241,241,1) 100%, rgba(241,241,241,1) 100%);

  background-color: var(--background-color);
  color: var(--primary-text-color);
  font-family: var(--primary-font-family);
  height: 100vh;
  overflow: hidden;
}

.App.darkMode {
  --background-color: #141414;
  --primary-text-color: #F1F1F1;
  --lower-gradient: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(20,20,20,1) 100%, rgba(20,20,20,1) 100%);
}


.App > .darkModeToggle {
  position: fixed;
  bottom: 1em;
  left: 1em;
  z-index: 2;
}

.App > .darkModeToggle > .toggle {
  border: 2px solid var(--primary-text-color);
  border-radius: 100px;
  cursor: pointer;
  display: block;
  height: 20px;
  overflow: hidden;
  transform: scale(1);
  transition: 250ms ease all;
  width: 20px;
}

.App > .darkModeToggle > .toggle:hover,
.App > .darkModeToggle > .toggle:active {
  transform: scale(1.25);
}

.App > .darkModeToggle > .toggle > .fill{
  background-color: var(--primary-text-color);
  display: block;
  height: 100%;
  width: 50%;
}
